<template>
  <div id="table-field">
    <span
      v-if="!openLabelInput && label"
      class="label"
      @click="handleOpenInput"
    >
      <FormFieldLabel
        v-if="label"
        :label="label"
        :is-mandatory="isMandatory"
        :tooltip="tooltip"
      />
    </span>

    <input
      v-if="openLabelInput"
      ref="focusMe"
      :value="label"
      type="text"
      autofocus
      class="label1"
      :class="!labelError ? 'q-mb-sm' : ''"
      @input="handleLabelInput"
      @mouseleave="handleFocusOut(label)"
    />

    <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />

    <!-- table -->

    <BaseScrollbar class="q-pb-sm" :class="{ 'is-disabled': isDisabled }">
      <table>
        <thead>
          <tr>
            <th
              v-for="column in _tableColumns"
              :key="column.id"
              :class="getClass(column.size)"
            >
              {{ column.label }}
              <span
                v-if="column.settings.validation.fieldRule === 'REQUIRED'"
                class="required"
              >
                *
              </span>
              <BaseIcon
                v-if="column.settings.general.tooltip"
                v-tooltip.top="column.settings.general.tooltip"
                inherit-color
                name="eva-question-mark-circle"
                class="info-icon"
              />
            </th>
            <th
              v-if="tableRowsType === 'ON_DEMAND' && !isReadonly"
              class="actions"
              :class="qrValue ? 'qr-icon' : ''"
            >
              <div class="row items-center justify-center">
                <!-- v-if="!qrValue" -->

                <BaseActionButton
                  v-tooltip.bottom="'add row'"
                  is-flat
                  color="secondary"
                  icon="eva-plus"
                  no-border
                  class="col-auto"
                  @click="addRow"
                />

                <BaseActionButton
                  v-if="qrValue && !isReadonly"
                  v-tooltip.bottom="'scan QR'"
                  is-flat
                  color="secondary"
                  icon="mdi-qrcode-scan"
                  no-border
                  class="col-auto"
                  @click="showQRRead = true"
                />
              </div>
            </th>
            <th
              v-if="tableRowsType === 'FIXED' && !isReadonly"
              class="actions"
              :class="qrValue ? 'qr-icon' : ''"
            >
              <div class="row items-center justify-center">
                <BaseActionButton
                  v-if="qrValue && !isReadonly"
                  v-tooltip.bottom="'scan QR'"
                  is-flat
                  color="secondary"
                  icon="mdi-qrcode-scan"
                  no-border
                  class="col-auto"
                  @click="showQRRead = true"
                />
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, idx) in rows" :key="row">
            <td v-for="column in _tableColumns" :key="column.id">
              <!-- label -->

              <div
                v-if="column.type === 'LABEL'"
                :key="column.id"
                class="label"
              >
                {{ getLabel(idx, column.id) }}
              </div>

              <!-- ... -->

              <!-- label -->

              <div
                v-if="column.type === 'SERIAL_NUMBER'"
                :key="column.id"
                class="label"
              >
                {{ getSerialNumber(idx, column.id) }}
              </div>

              <!-- ... -->

              <!-- calculated field -->

              <CalculatedFieldWrapper
                v-else-if="column.type === 'CALCULATED'"
                :key="column.id"
                :value="tableCalculatedFieldsModel[idx][column.id]"
                :field="column"
              />

              <!-- ... -->

              <!-- calculated field -->

              <PopupFieldWrapper
                v-else-if="column.type === 'POPUP'"
                :ref="`popup - ${column.id}`"
                :key="column.id"
                v-model="tableFieldsModel[idx][column.id]"
                :field="column"
                :is-readonly="isReadonly"
                :secondary-panels="secondaryPanels"
                :form-settings="formSettings"
              />

              <!-- ... -->

              <div
                v-else-if="column.type === 'SELECTION'"
                class="row items-center justify-center"
                style="height: 46px"
              >
                <BaseIcon
                  v-model="tableFieldsModel[idx][column.id]"
                  :name="
                    tableFieldsModel[idx][column.id]
                      ? 'eva-checkmark-circle-2'
                      : 'eva-radio-button-off-outline'
                  "
                  :color="
                    tableFieldsModel[idx][column.id] ? 'secondary' : 'grey'
                  "
                  class="cursor-pointer"
                  @click="
                    selectRow(idx, column.id, tableFieldsModel[idx][column.id])
                  "
                />
              </div>

              <!-- other fields -->

              <component
                :is="getField(column.type)"
                v-else
                :key="column.id"
                v-model="tableFieldsModel[idx][column.id]"
                :field="column"
                :form-id="formId"
                :is-readonly="checkReadonly(idx, column.id)"
                :parent-control="checkParentControl(column.id)"
                :filter="tableFieldsModelOption[idx][column.id]?.filter"
                :filter-by="tableFieldsModelOption[idx][column.id]?.filterBy"
                :row-index="idx"
                :list-options="tableFieldsModelOption[idx][column.id]?.options"
                :form-settings="formSettings"
                :is-mandatory="checkMandatory(idx, column.id)"
                settings-for="TABLE"
                @updateOptions="updateOptions"
                @filterBy="filterChildControlValues"
                @readonlyControls="setReadonlyControls"
                @mandatoryControls="setMandatoryControls"
                @uploadFiles="uploadFiles($event, idx)"
                @deletedFiles="deletedFiles($event, idx)"
                @showQr="showQr($event, idx)"
                @viewFile="viewFile"
              />
              <!-- :is-mandatory="modelOption[idx][column.id]?.isMandatory" -->

              <!-- ... -->
            </td>

            <!-- actions -->

            <td v-if="tableRowsType === 'ON_DEMAND' && !isReadonly">
              <div class="row items-center justify-center" style="height: 46px">
                <BaseActionButton
                  is-flat
                  color="red"
                  icon="eva-trash-2-outline"
                  no-border
                  @click="deleteRow(row)"
                />
              </div>
            </td>

            <td v-if="tableRowsType === 'FIXED' && !isReadonly"></td>

            <!-- ... -->
          </tr>

          <tr v-if="showColumnTotal">
            <td
              v-for="column in tableColumns"
              :key="column.id"
              class="text-center"
            >
              <div v-if="column.settings.specific.showColumnTotal">
                <NumberField
                  :value="getTableColumnSum(column.id)"
                  is-readonly
                />
              </div>
            </td>
            <td v-if="tableRowsType === 'ON_DEMAND' && !isReadonly"></td>
          </tr>
        </tbody>
      </table>
    </BaseScrollbar>

    <!-- ... -->

    <QRReader :value="showQRRead" :form-id="formId" @input="qrReadValue" />

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import ShortTextFieldWrapper from "@/components/common/form/nested-fields/ShortTextFieldWrapper.vue";
import SingleSelectFieldWrapper from "@/components/common/form/nested-fields/SingleSelectFieldWrapper.vue";
import MultiSelectFieldWrapper from "@/components/common/form/nested-fields/MultiSelectFieldWrapper.vue";
import NumberFieldWrapper from "@/components/common/form/nested-fields/NumberFieldWrapper.vue";
import CounterFieldWrapper from "@/components/common/form/nested-fields/CounterFieldWrapper.vue";
import CalculatedFieldWrapper from "@/components/common/form/nested-fields/CalculatedFieldWrapper.vue";
import DateFieldWrapper from "@/components/common/form/nested-fields/DateFieldWrapper.vue";
import TimeFieldWrapper from "@/components/common/form/nested-fields/TimeFieldWrapper.vue";
import ChipsFieldWrapper from "@/components/common/form/nested-fields/ChipsFieldWrapper.vue";
import FileUploadFieldWrapper from "@/components/common/form/nested-fields/FileUploadFieldWrapper.vue";
import PopupFieldWrapper from "@/components/common/form/nested-fields/PopupFieldWrapper.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import QRReader from "@/components/common/QRReader.vue";
// import { form } from "@/api/factory.js";
import SingleChoiceFieldWrapper from "@/components/common/form/nested-fields/SingleChoiceFieldWrapper";
import MultipleChoiceFieldWrapper from "@/components/common/form/nested-fields/MultiChoiceFieldWrapper";
import ImageFieldWrapper from "@/components/common/form/nested-fields/ImageFieldWrapper";
import {
  cloneDeep,
  isEmpty,
  lowerCase,
  upperFirst,
  camelCase,
} from "lodash-es";
import { evaluateFormula } from "@/helpers/formula.js";

export default {
  name: "TableField",

  components: {
    FormFieldLabel,
    FormFieldError,
    ShortTextFieldWrapper,
    NumberFieldWrapper,
    CounterFieldWrapper,
    CalculatedFieldWrapper,
    DateFieldWrapper,
    TimeFieldWrapper,
    ChipsFieldWrapper,
    SingleSelectFieldWrapper,
    MultiSelectFieldWrapper,
    FileUploadFieldWrapper,
    PopupFieldWrapper,
    NumberField,
    QRReader,
    SingleChoiceFieldWrapper,
    MultipleChoiceFieldWrapper,
    ImageFieldWrapper,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    label: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    tableColumns: {
      type: Array,
      required: true,
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    tableRowChooseType: {
      type: String,
      default: "",
    },

    tableRowsType: {
      type: String,
      default: "ON_DEMAND",
    },

    tableFixedRowCount: {
      type: Number,
      default: 0,
    },

    tableFixedRowLabels: {
      type: Array,
      default: () => [],
    },

    calculations: {
      type: Array,
      default: () => [],
    },

    formSettings: {
      type: Object,
      required: true,
    },

    qrValue: {
      type: Boolean,
      default: false,
    },

    formId: {
      type: String,
      default: "",
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    hasMasterTableChange: {
      type: Boolean,
      default: false,
    },

    mandatoryRow: {
      type: Array,
      default: () => [],
    },

    hasChangeTableRow: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isFocused: false,
      rows: 0,
      tableFieldsModel: [],
      tableFieldsModelOption: [],
      tableChoice: [
        {
          id: this.$nano.id(),
          label: "",
          value: false,
        },
      ],
      showQRRead: false,
      autoDelete: false,
      copyArrayJson: "",
      openLabelInput: false,
      labelError: "",
      readonlyControlsList: [],
      mandatoryControlsList: [],
      modelOption: [],
      hasRowChange: false,
    };
  },

  computed: {
    showColumnTotal() {
      for (let column of this.tableColumns) {
        if (column.settings.specific.showColumnTotal) {
          return true;
        }
      }

      return false;
    },

    tableCalculatedFieldsModel() {
      let model = cloneDeep(this.tableFieldsModel);
      model = model.map((row, idx) => {
        for (const column in row) {
          const key = column;
          const _column = this.tableColumns.find((column) => column.id === key);
          if (
            _column.type === "POPUP" &&
            _column.settings.specific.mappedFieldId
          ) {
            row[column] = this.$refs[`popup - ${key}`][idx].$children[0].label;
          }

          if (_column.type === "CALCULATED" && !isEmpty(this.calculations)) {
            const calculation = this.calculations.find(
              (_calculation) => _calculation.columnId === _column.id
            );
            let calcValue = evaluateFormula(
              calculation.formula,
              row,
              this.tableColumns
            );
            row[column] =
              calcValue === 0 ? 0 : parseFloat(calcValue.toFixed(2));
            this.tableFieldsModel[idx][column] = row[column];
          }
        }

        return row;
      });

      return model;
    },

    _tableColumns() {
      let tblColumns = [];
      this.tableColumns.forEach((col) => {
        if (col.settings.general.visibility !== "DISABLE") {
          tblColumns.push(col);
        }
      });
      return tblColumns;
    },
  },

  watch: {
    tableFieldsModel: {
      deep: true,
      handler() {
        let ocrControl = this.readonlyControlsList.filter(
          (item) => item.tableControl
        );
        //console.log(ocrControl, "ocrControl");
        ocrControl.forEach((ocr) => {
          let upload = this.tableFieldsModel[ocr.rowIndex][ocr.uploadFieldId];
          if (upload.length && ocr.isReadonly) {
            if (
              Object.prototype.hasOwnProperty.call(
                upload[ocr.fileIndex],
                "fileId"
              )
            ) {
              if (upload[ocr.fileIndex].fileId) {
                ocr.isReadonly = false;
              }
            }
          }
        });
        this.$emit("input", this.tableFieldsModel);
      },
    },

    tableColumns: {
      deep: true,
      handler: "resetModel",
    },

    tableRowsType() {
      this.addRows();
    },

    tableFixedRowCount() {
      if (this.tableFixedRowCount > this.rows) {
        const rowsToAdd = this.tableFixedRowCount - this.rows;

        for (let i = 1; i <= rowsToAdd; i++) {
          this.addRow();
        }
      }

      if (this.tableFixedRowCount < this.rows) {
        const rowsToRemove = this.rows - this.tableFixedRowCount;

        for (let i = 1; i <= rowsToRemove; i++) {
          this.rows--;
          this.tableFieldsModel.pop();
        }
      }
    },

    hasMasterTableChange: {
      handler() {
        if (this.value.length) {
          this.tableFieldsModel = cloneDeep(this.value);
          this.rows = this.value.length;
          const modelOption = {};
          const settings = {};
          this.tableFieldsModelOption = [];
          this.modelOption = [];
          this.tableFieldsModel.forEach((field) => {
            console.log(field);
            for (let column of this.tableColumns) {
              modelOption[column.id] = {
                id: column.id,
                filterBy: [],
                filter: 0,
                options: [],
              };
              settings[column.id] = {
                id: column.id,
                isReadonly: false,
                isMandatory: false,
              };
            }
            this.tableFieldsModelOption.push(modelOption);
            this.modelOption.push(settings);
          });
          // return;
          // this.modelOption.forEach((row, index) => {
          //   console.log(row, index);
          //   console.log(this.mandatoryRow);
          //   for (let col in row) {
          //     let columns = this.mandatoryRow.find((item) => item[col] === col);
          //     console.log(columns, col);
          //     if (columns) {
          //       console.log(columns[col], "col");

          //       this.modelOption[index][columns.col].isMandatory = true;
          //     }
          //   }
          // });

          this.tableFieldsModel.forEach((row, index) => {
            if (this.tableColumns.length === this._tableColumns.length) {
              if (index === 0) {
                for (let col in row) {
                  let columns = this.tableColumns.find(
                    (item) => item.id === col
                  );
                  // console.log(columns);
                  let settings = columns.settings.validation.mandatorySettings;
                  let settings1 = columns.settings.validation.readonlySettings;
                  if (settings && settings.length) {
                    let controls = settings.find(
                      (control) => control.value.trim() === row[col].trim()
                    );
                    // console.log(controls);
                    if (controls) {
                      this.setMandatoryControls(
                        controls.controls,
                        settings,
                        "immediate",
                        columns.id,
                        index
                      );
                    } else {
                      this.setMandatoryControls(
                        [],
                        settings,
                        "immediate",
                        columns.id,
                        index
                      );
                    }
                  }
                  if (settings1 && settings1.length) {
                    let controls = settings1.find(
                      (control) => control.value.trim() === row[col].trim()
                    );
                    // console.log(controls, "read");
                    if (controls) {
                      this.setReadonlyControls(
                        controls.controls,
                        settings,
                        "immediate",
                        columns.id,
                        index
                      );
                    } else {
                      this.setReadonlyControls(
                        [],
                        settings,
                        "immediate",
                        columns.id,
                        index
                      );
                    }
                  }
                }
              }
            } else {
              for (let col in row) {
                let columns = this.tableColumns.find((item) => item.id === col);
                // console.log(columns);
                let settings = columns.settings.validation.mandatorySettings;
                let settings1 = columns.settings.validation.readonlySettings;
                if (settings && settings.length) {
                  let controls = settings.find(
                    (control) => control.value.trim() === row[col].trim()
                  );
                  // console.log(controls);
                  if (controls) {
                    this.setMandatoryControls(
                      controls.controls,
                      settings,
                      "immediate",
                      columns.id,
                      index
                    );
                  } else {
                    this.setMandatoryControls(
                      [],
                      settings,
                      "immediate",
                      columns.id,
                      index
                    );
                  }
                }
                if (settings1 && settings1.length) {
                  let controls = settings1.find(
                    (control) => control.value.trim() === row[col].trim()
                  );
                  // console.log(controls, "read");
                  if (controls) {
                    this.setReadonlyControls(
                      controls.controls,
                      settings,
                      "immediate",
                      columns.id,
                      index
                    );
                  } else {
                    this.setReadonlyControls(
                      [],
                      settings,
                      "immediate",
                      columns.id,
                      index
                    );
                  }
                }
              }
            }
          });
        } else {
          this.tableFieldsModel = [];
          this.rows = this.value.length;

          this.tableFieldsModelOption = [];
          this.modelOption = [];

          // if (!this.qrValue) {
          //   this.addRows();
          // }
          this.addRows();
        }
      },
    },

    hasChangeTableRow: {
      handler() {
        // console.log(this.hasChangeTableRow, newVal, oldVal);

        if (this.hasChangeTableRow.length) {
          this.tableFieldsModel = cloneDeep(this.value);
          this.rows = this.value.length;
          const modelOption = {};
          const settings = {};
          this.tableFieldsModel.forEach((field) => {
            console.log(field);
            for (let column of this.tableColumns) {
              modelOption[column.id] = {
                id: column.id,
                filterBy: [],
                filter: 0,
                options: [],
              };

              settings[column.id] = {
                id: column.id,
                isMandatory: false,
                isReadonly: false,
              };
            }
            this.tableFieldsModelOption.push(modelOption);
            this.modelOption.push(settings);
          });
        }
      },
    },
  },

  created() {
    if (this.value.length) {
      this.tableFieldsModel = cloneDeep(this.value);
      this.rows = this.value.length;
      const modelOption = {};
      const settings = {};
      this.tableFieldsModel.forEach((field) => {
        console.log(field);
        for (let column of this.tableColumns) {
          modelOption[column.id] = {
            id: column.id,
            filterBy: [],
            filter: 0,
            options: [],
          };

          settings[column.id] = {
            id: column.id,
            isMandatory: false,
            isReadonly: false,
          };
        }
        this.tableFieldsModelOption.push(modelOption);
        this.modelOption.push(settings);
      });
      return;
    }
    // if (!this.qrValue) {
    //   this.addRows();
    // }
    this.addRows();
  },

  methods: {
    getClass(columnSize) {
      return lowerCase(columnSize);
    },

    addRows() {
      this.tableFieldsModel = [];
      this.rows = 0;

      if (this.tableRowsType === "FIXED" && this.tableFixedRowCount) {
        for (let i = 1; i <= this.tableFixedRowCount; i++) {
          this.addRow();
        }
        return;
      }

      this.addRow();
    },

    getField(fieldType) {
      return `${upperFirst(camelCase(fieldType))}FieldWrapper`;
    },

    getInitialValue(columnType) {
      // console.log(columnType);
      switch (columnType) {
        case "MULTI_SELECT":
          return [];
        case "FILE_UPLOAD":
        case "CHIPS":
          return [];
        case "COUNTER":
        case "CALCULATED":
          return 0;
        case "POPUP":
          return {};
        case "SELECTION":
          return false;
        default:
          return "";
      }
    },

    addModel() {
      const model = {};
      const modelOption = {};
      const settings = {};

      for (let column of this.tableColumns) {
        // model[column.id] = this.getInitialValue(column.type);
        model[column.id] = this.getDefaultValue(column);
        modelOption[column.id] = {
          id: column.id,
          filterBy: [],
          filter: 0,
          options: [],
        };

        settings[column.id] = {
          id: column.id,
          isMandatory: false,
          isReadonly: false,
        };
      }

      this.tableFieldsModel.push(model);
      this.tableFieldsModelOption.push(modelOption);
      this.modelOption.push(settings);
    },

    resetModel() {
      this.tableFieldsModel = [];

      for (let i = 1; i <= this.rows; i++) {
        this.addModel();
      }
    },

    addRow() {
      this.rows++;
      this.addModel();
    },

    deleteRow(row) {
      const rowIdx = row - 1;
      this.tableFieldsModel.splice(rowIdx, 1);
      this.rows--;
      let mandatory = this.mandatoryControlsList.findIndex((item) => {
        return item.rowIndex === rowIdx;
      });
      if (mandatory >= 0) this.mandatoryControlsList.splice(mandatory, 1);
      let readonly = this.readonlyControlsList.findIndex((item) => {
        return item.rowIndex === rowIdx;
      });
      if (readonly >= 0) this.readonlyControlsList.splice(readonly, 1);
    },

    getLabel(rowIdx, columnId) {
      if (isEmpty(this.tableFixedRowLabels)) {
        return "";
      }

      return this.tableFixedRowLabels[rowIdx][columnId];
    },

    getTableColumnSum(columnId) {
      if (!this.tableFieldsModel.length) {
        return 0;
      }
      let rows = this.tableFieldsModel.filter((row) => row[columnId]).length;
      if (rows) {
        if (
          this.copyArrayJson !== JSON.stringify(this.tableFieldsModel) &&
          !this.autoDelete
        ) {
          this.autoDelete = true;
          this.addRow();
        } else if (this.autoDelete && this.tableFieldsModel.length > 1) {
          this.autoDelete = false;
          this.deleteRow(this.tableFieldsModel.length);
          this.copyArrayJson = JSON.stringify(this.tableFieldsModel);
        }
      }
      return this.tableFieldsModel.reduce((acc, cur) => acc + cur[columnId], 0);
    },

    selectRow(rowIndex, columnId, value) {
      if (this.tableRowChooseType === "SINGLE_ROW") {
        this.tableFieldsModel.forEach((row) => {
          row[columnId] = false;
        });
      }
      this.tableFieldsModel[rowIndex][columnId] = !value;
    },

    checkParentControl(fieldId) {
      let parentFieldFound = false;
      this.tableColumns.forEach((field) => {
        if (field.settings.specific.masterFormParentColumn) {
          if (fieldId === field.settings.specific.masterFormParentColumn) {
            parentFieldFound = true;
            return;
          }
        }
      });
      this.tableColumns.forEach((field) => {
        if (field.settings.specific.repositoryFieldParent) {
          if (fieldId === field.settings.specific.repositoryFieldParent) {
            parentFieldFound = true;
            return;
          }
        }
      });
      return parentFieldFound;
    },

    filterChildControlValues(field, rowIndex) {
      this.tableColumns.filter((rowField) => {
        if (rowField.settings.specific.masterFormParentColumn) {
          if (field.id === rowField.settings.specific.masterFormParentColumn) {
            this.tableFieldsModelOption[rowIndex][rowField.id].filterBy = [
              {
                criteria: field.settings.specific.masterFormColumn,
                condition: "IS_EQUALS_TO",
                value: this.tableFieldsModel[rowIndex][field.id],
                dataType: "",
              },
            ];
            this.tableFieldsModelOption[rowIndex][rowField.id].filter =
              this.tableFieldsModelOption[rowIndex][rowField.id].filter + 1;
          }
        } else if (rowField.settings.specific.repositoryFieldParent) {
          if (field.id === rowField.settings.specific.repositoryFieldParent) {
            this.tableFieldsModelOption[rowIndex][rowField.id].filterBy = [
              {
                criteria: field.settings.specific.repositoryField,
                condition: "IS_EQUALS_TO",
                value: this.tableFieldsModel[rowIndex][field.id],
                dataType: "",
              },
            ];
            this.tableFieldsModelOption[rowIndex][rowField.id].filter =
              this.tableFieldsModelOption[rowIndex][rowField.id].filter + 1;
          }
        }
      });
    },

    updateOptions(field, rowIndex, options) {
      this.tableFieldsModelOption[rowIndex][field.id].options = options;
    },

    async qrReadValue(qrData) {
      this.showQRRead = false;
      if (qrData) {
        const qrObj = JSON.parse(qrData);
        let filter = [];
        for (const keyName in qrObj) {
          if (keyName !== "URL") {
            let col = this.tableColumns.find((row) => row.label === keyName);
            if (col) {
              filter.push({
                criteria: col.id,
                condition: "IS_EQUALS_TO",
                value: qrObj[keyName],
              });
            }
          }
        }
        // this.$store.commit("showLoadingBar");

        // const { error, payload } = await form.getFormEntries(this.formId, {
        //   filterBy: [{ filters: filter }],
        // });

        // this.$store.commit("hideLoadingBar");

        // if (error) {
        //   this.$alert.error(error);
        //   return;
        // }
        // const { data } = payload;
        // console.log(data);
        // if (data.length) {
        //   this.$alert.info("Already used");
        // } else {
        let idx = this.tableFieldsModel.length - 1;
        this.tableFieldsModel.forEach((row) => {
          for (const column in row) {
            const key = column;
            const _column = this.tableColumns.find(
              (column) => column.id === key
            );
            for (const keyName in qrObj) {
              if (_column.label === keyName) {
                if (
                  _column.type === "NUMBER" ||
                  _column.type === "CALCULATED"
                ) {
                  this.tableFieldsModel[idx][column] = Number(qrObj[keyName]);
                } else {
                  this.tableFieldsModel[idx][column] = qrObj[keyName];
                }
              }
            }
          }
        });
        if (this.tableRowsType === "ON_DEMAND") {
          this.addRow();
        }
        // }
      }
    },

    getDefaultValue(field) {
      const defaultValue = field.settings.specific.defaultValue;

      if (defaultValue === "USER_EMAIL") {
        return this.$store.state.session.email;
      }

      if (defaultValue === "USER_NAME") {
        let name = "";
        if (this.$store.state.session.firstName) {
          name = this.$store.state.session.firstName;
        }
        if (this.$store.state.session.lastName) {
          name += " " + this.$store.state.session.lastName;
        }
        return name;
      }

      if (defaultValue === "CURRENT_DATE") {
        return this.$day.newDate();
      }

      if (defaultValue === "CURRENT_TIME") {
        return this.$day.newTime();
      }

      if (defaultValue === "CURRENT_DATE_TIME") {
        return this.$day.newDateTime();
      }

      if (defaultValue === "AUTO_GENERATE") {
        const prefix = field.settings.specific.autoGenerateValue.prefix;
        const suffixFormat = field.settings.specific.autoGenerateValue.suffix;

        const suffixFormats = {
          DATE_TIME: "YYYYMMDDhhmmss",
          DATE: "YYYYMMDD",
          TIME: "hhmmss",
        };

        const suffix = this.$day.customFormat(suffixFormats[suffixFormat]);

        return `${prefix} - ${suffix}`;
      }
      if (defaultValue === "CUSTOM") {
        return (
          field.settings.specific.customDefaultValue ||
          this.getEmptyDefaultValue(field.type)
        );
      }
    },

    getEmptyDefaultValue(fieldType) {
      switch (fieldType) {
        case "MULTI_SELECT":
          return [];
        case "MULTIPLE_CHOICE":
        case "FILE_UPLOAD":
        case "IMAGE_UPLOAD":
        case "TABLE":
        case "MATRIX":
        case "CHIPS":
        case "NESTED_LIST":
          return [];
        case "COUNTER":
        case "CALCULATED":
        case "RATING":
          return 0;
        case "FILL_IN_THE_BLANKS":
        case "POPUP":
        case "TABS":
          return {};
        default:
          return "";
      }
    },

    handleFocusOut(label) {
      if (!label || this.labelError) {
        return;
      }
      this.openLabelInput = false;
    },

    handleLabelInput(evt) {
      let label = evt.target.value;
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.label.toLowerCase() === label.toLowerCase()) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", evt.target.value);
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },

    checkReadonly(index, fieldId) {
      if (this.isReadonly) {
        return true;
      } else {
        if (this.readonlyControlsList.length) {
          let readonly = this.readonlyControlsList.find(
            (list) => list.rowIndex === index && list.id === fieldId
          );
          // console.log(readonly);
          if (readonly) {
            return readonly.isReadonly;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },

    setReadonlyControls(controls, settings, immediate, fieldId, rowIndex) {
      // console.log(controls, settings, immediate, fieldId, rowIndex);
      if (controls.length) {
        controls.forEach((item) => {
          let columns = this.tableColumns.findIndex((column) => {
            return column.id === item;
          });
          // console.log(columns, "index");
          if (columns > -1) {
            // this.tableFieldsModelOption[rowIndex][item].isReadOnly = true;
            // this.readonlyControlsList.push({
            //   rowIndex: rowIndex,
            //   fieldsIndex: columns,
            //   id: item,
            //   fieldId: fieldId,
            // });

            if (this.readonlyControlsList.length) {
              let readonly = this.readonlyControlsList.find(
                (list) => list.rowIndex === rowIndex && list.fieldId === fieldId
              );
              // console.log(readonly);
              if (!readonly) {
                this.readonlyControlsList.push({
                  rowIndex: rowIndex,
                  fieldsIndex: columns,
                  id: item,
                  fieldId: fieldId,
                  isReadonly: true,
                });
              } else {
                readonly.isReadonly = true;
              }
            } else {
              this.readonlyControlsList.push({
                rowIndex: rowIndex,
                fieldsIndex: columns,
                id: item,
                fieldId: fieldId,
                isReadonly: true,
              });
            }
          }
        });
      } else {
        if (this.readonlyControlsList.length) {
          this.readonlyControlsList.forEach((control, index) => {
            // console.log(control.fieldId, control.rowIndex);
            if (fieldId === control.fieldId && rowIndex === control.rowIndex) {
              // this.tableFieldsModelOption[rowIndex][
              //   control.id
              // ].isReadOnly = false;

              this.readonlyControlsList[index].isReadonly = false;
            }
          });
          // this.readonlyControlsList = [];
        }
      }
    },

    setMandatoryControls(controls, settings, immediate, fieldId, rowIndex) {
      //console.log(controls, settings, immediate, fieldId, rowIndex);
      if (controls.length) {
        controls.forEach((item) => {
          let columns = this.tableColumns.findIndex((column) => {
            return column.id === item;
          });

          if (columns > -1) {
            // this.modelOption[rowIndex][item].isMandatory = true;
            if (this.mandatoryControlsList.length) {
              let mandatory = this.mandatoryControlsList.find(
                (list) => list.rowIndex === rowIndex && list.fieldId === fieldId
              );
              // console.log(mandatory);
              if (!mandatory) {
                this.mandatoryControlsList.push({
                  rowIndex: rowIndex,
                  fieldsIndex: columns,
                  id: item,
                  fieldId: fieldId,
                  isMandatory: true,
                });
              } else {
                mandatory.isMandatory = true;
              }
            } else {
              this.mandatoryControlsList.push({
                rowIndex: rowIndex,
                fieldsIndex: columns,
                id: item,
                fieldId: fieldId,
                isMandatory: true,
              });
            }
          }
        });
      } else {
        if (this.mandatoryControlsList.length) {
          this.mandatoryControlsList.forEach((control, index) => {
            if (fieldId === control.fieldId && rowIndex === control.rowIndex) {
              // this.modelOption[rowIndex][control.id].isMandatory = false;
              // this.mandatoryControlsList.splice(index, 1);
              this.mandatoryControlsList[index].isMandatory = false;
            }
          });
        }
      }
    },

    checkMandatory(index, fieldId) {
      if (this.mandatoryControlsList.length) {
        let mandatory = this.mandatoryControlsList.find(
          (list) => list.rowIndex === index && list.id === fieldId
        );
        // console.log(mandatory);
        if (mandatory) {
          return mandatory.isMandatory;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    getSerialNumber(idx, columnId) {
      this.tableFieldsModel[idx][columnId] = idx + 1;
      return idx + 1;
    },

    showQr(id, index) {
      let emitValue = {
        fieldId: id,
        rowIndex: index,
      };
      this.$emit("showQr", emitValue);
    },

    deletedFiles(ids, index) {
      let emitValue = {
        fileIds: ids.deletedFiles,
        settings: ids.settings,
        rowIndex: index,
      };
      console.log(ids.settings);
      if (ids.settings.controls) {
        ids.settings.controls.forEach((id) => {
          this.tableFieldsModel[index][id] = "";

          let indexed = this.readonlyControlsList.findIndex((item) => {
            return item.id === id && item.tableControl;
          });
          if (indexed > -1) {
            this.readonlyControlsList.splice(indexed, 1);
          }
        });
      }
      this.$emit("deletedFiles", emitValue);
    },

    uploadFiles(files, index) {
      let emitValue = {
        files: files,
        rowIndex: index,
      };
      files.assignControls.forEach((fieldId, fileIndex) => {
        this.readonlyControlsList.push({
          rowIndex: index,
          id: fieldId,
          isReadonly: true,
          tableControl: true,
          uploadFieldId: files.fieldId,
          fileIndex: fileIndex,
        });
      });

      this.$emit("uploadFiles", emitValue);
    },

    viewFile(file) {
      this.$emit("viewFile", file);
    },
  },
};
</script>

<style lang="scss">
#table-field {
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.small {
        width: 70px;
      }

      &.medium {
        width: 140px;
      }

      &.large {
        width: 210px;
      }

      &.actions {
        width: 48px;
        &.qr-icon {
          width: 60px;
        }
      }

      .required {
        color: red;
        margin-left: 3px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
    }

    td {
      padding: 4px;
      vertical-align: top;
    }

    td .label {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 4px;
      color: var(--icon-color);
    }
  }

  .label1 {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25rem;
  }

  .label:hover {
    cursor: auto;
  }

  .info-icon {
    margin-left: 8px;
    cursor: pointer;
    color: var(--icon-color-inverted);
    margin-top: -2px;
  }
}
</style>
